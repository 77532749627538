/** @typedef {import('../Callidus').SparsePost} SparsePost */
/** @typedef {import('../Callidus').Reply} Reply */
import { fetchFromAPI, fetchJSONFromAPI, postJSONToAPI } from './api';

const API_ENDPOINT = '/comments';
const COMMENT_COUNTER_TIMEOUT = 5 * 60 * 1000; // 5 minutes

/** @type {Object<string, {
 *   cacheBuster: any,
 *   count: number,
 * }>} */
const COMMENT_COUNTERS = {};

/**
 * Safe way of clearing a cache entry, will clear existing timeout.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to cache reply-count
 * @returns {boolean} `true` if an entry was cleared
 */
function clearCommentCounter(post) {
  if (COMMENT_COUNTERS[post.id]) {
    const counterTuple = COMMENT_COUNTERS[post.id];
    window.clearTimeout(counterTuple.cacheBuster);
    delete(COMMENT_COUNTERS[post.id]);
    return true;
  }
  return false;
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to cache reply-count
 * @param {number} count ...
 */
function setCommentCounter(post, count) {
  clearCommentCounter(post);
  const counterTuple = {
    cacheBuster: window.setTimeout(function cacheBuster() {
      clearCommentCounter(post);
    }, COMMENT_COUNTER_TIMEOUT),
    count,
  };
  COMMENT_COUNTERS[post.id] = counterTuple;
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to cache reply-count
 * @returns {number} count `-1` if no counter exists
 */
function getCommentCounter(post) {
  return COMMENT_COUNTERS[post.id]?.count ?? -1;
}

/**
 * Fetch the replies for a specific post.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch replies
 * @returns {Promise<import('../Callidus').Reply[]>}
 *          Resolves to the array of replies (cleaning up any pre-amble)
 */
export async function fetchRepliesByPost(post) {
  const results = await fetchJSONFromAPI(
      API_ENDPOINT,
      {
        'post': `${post.id}`,
        'per_page': '50',
      },
  );

  const replies = results;

  return replies || []; // Just in case
}

/**
 * Fetch the replies for a specific post.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch replies
 * @returns {Promise<number>}
 *          Resolves to the array of replies (cleaning up any pre-amble)
 */
export async function fetchRepliesCountByPost(post) {
  const cachedCount = getCommentCounter(post);
  if (cachedCount >= 0) {
    return cachedCount;
  }

  const result = await fetchFromAPI(
      API_ENDPOINT,
      {
        'post': `${post.id}`,
        'per_page': '1',
      },
  );

  const count = Number(result.headers.get('x-wp-total')) || 0;
  setCommentCounter(post, count);

  return count;
}

/**
 * @param {string} reply The simple text-string reply
 * @param {SparsePost} post Which post this reply adheres to
 * @param {{
 *   name: string,
 *   email: string,
 * }} user A simple user object
 * @param {Reply} [parent] Which parent this reply adheres to
 * @returns {Promise<object>} See WP-JSON Api for expected content
 */
export async function sendReply(reply, post, user, parent) {
  const data = {
    'author_name': user.name,
    'author_email': user.email,
    'post': post.id,
    'content': reply,
  };

  if (parent) {
    data['parent'] = parent.id;
  }

  clearCommentCounter(post); // Because we added at least 1
  return postJSONToAPI(API_ENDPOINT, data);
}
