import { fetchNavFromAPI } from './api';

const API_ENDPOINT = '/menu-locations';

/**
 * Fetch all posts
 *
 * @param {string} location The location of the navigation
 * @returns {Promise<import('../Callidus').Navitem[]>} resolves to navigation.
 */
export async function fetchNavigationByLocation(location) {
  return fetchNavFromAPI(
      API_ENDPOINT,
      location,
  );
}
