import React, { useEffect, useState } from 'react';
import { fetchForm } from '../dao/forms';
import DefaultView from '../pages/DefaultView';
import usePage from '../pages/usePage';
import FormFactory from './FormFactory';

/**
 * @returns {JSX.Element|null} ...
 */
export default function FormView() {
  const page = usePage('feedback');
  const [ formData, setFormData ] = useState({});

  useEffect(() => {
    fetchForm('1').then((result) => {
      setFormData(result);
    });
  }, []);

  if (page === null) return null;

  return <DefaultView page={ page }>
    <FormFactory formData={ formData } />
  </DefaultView>;
}
