import React from 'react';

/**
 * @returns {JSX.Element} ...
 */
export default function AboutView() {
  return (<section>
    <div className="scroll-indicator--right">
      right
    </div>
    <div className="scroll-indicator--left">
      left
    </div>
    <div className="scroll-indicator--right scroll-indicator--left">
      both
    </div>
  </section>);
}
