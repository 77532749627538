import React from 'react';
import { useParams } from 'react-router';
import SubNavigation from '../navigation/SubNavigation';
import { NAVIGATION_TYPES } from '../Constants';
import OfflineView from './OfflineView';
import Title from '../title/Title';
import { urlParser } from '../helpers/url';

const BASE_CLASS = 'default-view';

/**
 * @param {{
 *   page: import('../Callidus').Page,
 *   className?: string,
 *   offline?: boolean,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function DefaultView({ page, offline, className, children }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   * @property {string} topic Unique identifier
   */
  const { topic, slug } = useParams();

  const baseClass = className || BASE_CLASS;

  if (offline) {
    return (<OfflineView { ...{
      page,
      children,
    } }/>);
  }

  return <article className={ baseClass }>
    <Title
      title={ page.title?.rendered }
      titleClass={ `${baseClass}__title` }
    />
    <SubNavigation
      topic={ NAVIGATION_TYPES[topic] || NAVIGATION_TYPES[slug] }
    />
    { children || <article dangerouslySetInnerHTML={ {
      __html: urlParser(page.content?.rendered),
    } }
    className={ `${baseClass}__content` }/> }
  </article>;
}
