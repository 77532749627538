import { fetchJSONFromAPI } from './api';

const API_ENDPOINT = '/pages';

/**
 * Fetch a single page by slug
 *
 * @param {string} slug The unique slug of the page
 * @returns {Promise<import('../Callidus').Page>} resolves to page.
 */
export async function fetchPageBySlug(slug) {
  const pages = await fetchJSONFromAPI(
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        'slug': slug,
      },
  );

  return pages.shift(); // Take the first page of the top
}
