import React from 'react';
import logo from './../assets/images/Callidus-logo.png';

/**
 * @param {{
 *   titleClass?: string,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export function TitleContainer({ children, titleClass }) {
  return <div className={ `main-title ${titleClass}` }>
    { children }
    <img className="main-title__logo" src={ logo } alt="Callidus"/>
  </div>;
}

/**
 * @param {{
 *   title: string,
 *   titleClass: string,
 * }} arg React props
 *
 * @returns {JSX.Element} React component
 */
export default function Title({ title, titleClass }) {
  return <TitleContainer titleClass={ titleClass }>
    <h1 dangerouslySetInnerHTML={ {
      __html: title,
    } }/>
  </TitleContainer>;
}
