/** @typedef {import("../Callidus").SparsePost} SparsePost */

import { useEffect, useState } from 'react';

const LOCALSTORAGE_KEY = 'calidus.counters';

/**
 * @param {string} counterKey The specific counter key
 * @returns {string} The combined local-storage with unique key
 */
function key(counterKey) {
  return `${LOCALSTORAGE_KEY}.${counterKey}`;
}

/**
 * @param {string} counterKey The specific counter key
 * @returns {Object<string, any>} Parsed localStorage item
 */
function getLocalCounter(counterKey) {
  const localCounterString = localStorage.getItem(key(counterKey)) || '{}';
  return JSON.parse(localCounterString);
}

/**
 * @param {string} counterKey The specific counter key
 * @param {boolean} counter Wether or not user is counted
 * @param {SparsePost} post Potentially counted post
 */
function setLocalCounter(counterKey, counter, post) {
  const localCounted = getLocalCounter(counterKey);
  localCounted[post.id] = counter;
  localStorage.setItem(key(counterKey), JSON.stringify(localCounted));
}

/**
 * @param {SparsePost} post Potentially counted post
 * @param {string} fieldName Fieldname of the counter on post
 * @param {Function} addToCounter Method to call to increase counter
 * @param {Function} retractFromCounter Method to call to decrease counter
 * @returns {[boolean, string, Function, boolean]} [isCounted, setCounted]
 */
export default function useCounter(
    post,
    fieldName,
    addToCounter,
    retractFromCounter,
) {
  const [ isCounted, setCounted ] = useState(false);
  const [ isInitialized, setInitialized ] = useState(false);
  const [ countersCount, setCountersCount ] = useState(
      post?.[fieldName] || '0',
  );

  useEffect(() => {
    const localCounted = getLocalCounter(fieldName);
    setCounted(localCounted[post?.id] || false);
    setCountersCount(post?.[fieldName] || '0');
    if (post) {
      setInitialized(true);
    }
  }, [ post, fieldName ]);

  /**
   * @param {boolean} doCount Wether or not user is counted
   * @returns {Promise<any>} request to backend
   */
  function guardCounted(doCount) {
    setLocalCounter(fieldName, doCount, post);
    setCounted(doCount);
    const postToServer = doCount ? addToCounter : retractFromCounter;
    return postToServer(post).then(({ data: { value } }) => {
      setCountersCount(value || 0);
    }).catch((e) => {
      // revert
      setLocalCounter(fieldName, !doCount, post);
      setCounted(!doCount);
      throw new Error('Failed to update backend, reverting counter');
    });
  }

  return [ isCounted, countersCount, guardCounted, isInitialized ];
}
