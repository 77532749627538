import React from 'react';
import SubNavigation from '../navigation/SubNavigation';
import { NAVIGATION_TYPES } from '../Constants';
import { urlParser } from '../helpers/url';

/**
 * @param {{
 *   page: import('../Callidus').Page,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function OfflineView({ page, children }) {
  return <article className="default-view">
    <h1 dangerouslySetInnerHTML={ {
      __html: page.title?.rendered,
    } }
    className="default-view__title"/>
    <SubNavigation
      topic={ NAVIGATION_TYPES['offline'] }
    />
    { children || <article dangerouslySetInnerHTML={ {
      __html: urlParser(page.content?.rendered),
    } }
    className="default-view__content"/> }
  </article>;
}
