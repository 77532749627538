/** @typedef {import('../Callidus').SparseEvent} SparseEvent */
/** @typedef {import('../Callidus').Event} Event */
import { useEffect, useState } from 'react';
import { fetchEvents, fetchEventBySlug } from '../dao/events';

/** @type {SparseEvent[]} */
let eventsCache = [];

/**
 * Basic hook to fetch events from the server and cache it in state.
 *
 * @returns {Array<import('../Callidus').SparseEvent[]>}
 *          returns the `posts` state.
 */
export default function useEvents() {
  const [ posts, setPosts ] = useState(eventsCache);

  useEffect(() => {
    fetchEvents()
        .then((posts) => eventsCache = posts)
        .then((posts) => setPosts(posts))
    ;
  }, []);

  return [ posts ];
}

/**
 * @param {string} slug Unique slug for the event
 * @returns {Event | SparseEvent} Returns an `Event` as a state-controlled value
 */
export function useEvent(slug) {
  const [ event, setEvent ] = useState(
      eventsCache.find((post) => post.slug === slug),
  );

  useEffect(() => {
    fetchEventBySlug(slug)
        .then((event) => setEvent(event || null))
    ;
  }, [ slug ]);

  return event;
}
