import React from 'react';
import ReactDOM from 'react-dom';

import version from './helpers/version';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ServiceWorkerProviderComponent,
} from './serviceworker/ServiceWorkerContext';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://967ff42d2f3a49659c37ede500b1d55b@o230677.ingest.sentry.io/5960384',
  integrations: [ new Integrations.BrowserTracing() ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

version();

ReactDOM.render(
    <React.StrictMode>
      <ServiceWorkerProviderComponent>
        <Router>
          <App />
        </Router>
      </ServiceWorkerProviderComponent>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
