import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { Offline, Online } from 'react-detect-offline';
import PostView from './posts/PostView';
import NavigationView from './navigation/NavigationView';
import PageView from './pages/PageView';
import EventView from './events/EventView';
import { GA_TRACKING_ID, OKTA_CLIENT_ID, OKTA_DOMAIN } from './Constants';
import ReactGA from 'react-ga';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import HomeView from './HomeView';
import FormView from './form/FormView';
import Installbanner from './pwa/Installbanner';
import SlugRouter from './pages/SlugRouter';
import AboutView from './helpers/AboutView';

export const oktaAuth = new OktaAuth({
  issuer: OKTA_DOMAIN,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

const OFFLINE_POLLING = {
  enabled: false,
};

/**
 * Trigger the serviceWorker to test and potentially refresh
 * offline content.
 */
function triggerOfflineRefresh() {
  navigator?.serviceWorker?.controller?.postMessage(JSON.stringify({
    action: 'REFRESH_OFFLINE_CONTENT',
  }));
}

/**
 * @param {{
 *   keyCode: number,
 *   ctrlKey: boolean,
 *   target: EventTarget
 * }} arg A basic keydownevent
 */
function detectFormSubmitByShortCut({
  keyCode,
  ctrlKey,
  target,
}) {
  if (ctrlKey && keyCode === 13) {
    const input = /** @type {HTMLFormElement} */ (target);
    input?.form?.querySelector('input[type="submit"]')?.click();
  }
}

/**
 * Hit GA for a page-view
 */
function trackPageView() {
  // Using window.location because this listener is not receiving
  // the parameters defined in it's Typescript
  const page = window.location.pathname;
  ReactGA.set({ page });
  ReactGA.pageview(page);
}

/**
 * This is the main entry-point into the app.
 *
 * @returns {JSX.Element} The app container
 */
function App() {
  useEffect(() => {
    triggerOfflineRefresh();
    document.addEventListener('keydown', detectFormSubmitByShortCut);
    ReactGA.initialize(GA_TRACKING_ID);
    trackPageView();
  }, []);
  const history = useHistory();

  history.listen(trackPageView);

  /**
   * Newer Okta requires you to add your own `restoreOriginalUri` implementation
   * To restore the uri we need `history` and for that we need to be a
   * _descendant_ of <Router>.
   *
   * @param {OktaAuth} _oktaAuth OktaOauth instance passed in by okta
   * @param {string} originalUri URI the user was on when auth was requested
   */
  async function restoreOriginalUri(_oktaAuth, originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      <div className="App">
        <NavigationView />
        <Online polling={ OFFLINE_POLLING }><Switch>
          <Route path='/login/callback' component={ LoginCallback } />
          <Route path='/about' component={ AboutView } />
          <SecureRoute path="/post/:slug">
            <PostView />
          </SecureRoute>
          <SecureRoute path="/feedback">
            <FormView />
          </SecureRoute>
          <SecureRoute path="/callidus-events/:slug">
            <EventView />
          </SecureRoute>
          <SecureRoute path="/:topic/:slug">
            <PageView />
          </SecureRoute>
          <SecureRoute path="/:slug">
            <SlugRouter />
          </SecureRoute>
          <SecureRoute path="/" exact>
            <HomeView />
          </SecureRoute>
        </Switch></Online>
        <Offline polling={ OFFLINE_POLLING }><Switch>
          <Route path="/:topic/:slug">
            <PageView offline={ true } />
          </Route>
          <Route path="/">
            <PageView offline={ true } />
          </Route>
        </Switch></Offline>
        <Installbanner />
      </div>
    </Security>
  );
}

export default App;
