import React from 'react';
import { useParams } from 'react-router';
import ContactView from './ContactView';
import DefaultView from './DefaultView';
import OfflineView from './OfflineView';
import ChartView from './ChartView';
import FeedView from './FeedView';
import usePage from './usePage';

const VIEW_TYPES = {
  'feed': FeedView,
  'contact': ContactView,
  'default': DefaultView,
  'offline': OfflineView,
  'chart': ChartView,
};

/**
 * @param {{
 *  offline?: boolean
 * }} arg React props
 * @returns {JSX.Element|null} ...
 */
export default function PageView({ offline }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  const page = usePage(slug);

  if (page === null) return null;

  const ViewType = VIEW_TYPES[page.template] || VIEW_TYPES['default'];

  return <ViewType page={ page } offline={ offline }/>;
}
