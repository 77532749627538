import React from 'react';
import DefaultView from './DefaultView';

/**
 * @param {{
 *   page: import('../Callidus').Page,
 *   offline?: boolean,
 *   children?: JSX.Element | JSX.Element[],
 * }} props React props
 * @returns {JSX.Element} ...
 */
export default function ChartView(props) {
  return <DefaultView className="chart-view" { ...props } />;
}
