import React from 'react';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';

const formatter = DateTimeFormatter.ofPattern('dd-MM-yyyy');

/**
 * @typedef {object} TimeStampProps
 * @property {string} date The date as ISO-8601 formatted string
 * @property {string} [className] Optional className
 * @property {JSX.Element | JSX.Element[]} [children] Optional nested content
 */

/**
 * @param {TimeStampProps} arg React props
 * @returns {JSX.Element} A time element showing localised time
 */
export default function TimeStamp({ date, className, children }) {
  const time = LocalDate.parse(String(date).slice(0, 10));
  return <time
    className={ className || 'timestamp' }
    dateTime={ date }
  >
    { children }
    { time.format(formatter) }
  </time>;
}
