import React from 'react';
import useEvents from '../events/useEvents';
import BlockViewFactory from './BlockViewFactory';
import usePosts from './usePosts';

/**
 * @returns {JSX.Element} The goods
 */
export default function PostsList() {
  const [ posts ] = usePosts();
  const [ events ] = useEvents();

  const allPosts = posts.concat(events)
      .sort(
          (a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0,
      )
  ;
  return <nav className="posts-list">{ allPosts.map((post) => (
    <BlockViewFactory key={ post.id } post={ post }/>
  )) }</nav>;
}
