import React from 'react';
import useTextField from './useFormField';

/** @typedef {import('./useFormField').GenericFormElement} GenericFormElement */

const BASE_CLASS = 'radio-group';
const SUB_CLASS = 'radio-choice';

/**
 * @param {{
 *  choice: Object<string, any>,
 *  fieldName: string,
 *  formFieldProps: {
 *    value: string | number,
 *    onChange: React.ChangeEventHandler<GenericFormElement>
 *  }
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function RadioChoice({ choice, fieldName, formFieldProps }) {
  return <label className={ `${SUB_CLASS}` }>
    <input
      type='radio'
      className={ `${SUB_CLASS}__input` }
      value={ choice.value }
      name={ fieldName }
      onChange={ formFieldProps.onChange }
      checked={ choice.value === formFieldProps.value }
    />
    <hgroup className={ `${SUB_CLASS}__label` }>{ choice.text }</hgroup>
  </label>;
}

/**
 * @param {{
 *  field: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function RadioGroup({ field }) {
  const fieldName = `${SUB_CLASS}${field.id}`;
  const formFieldProps = useTextField(field.id);

  return <section className={ `${BASE_CLASS}` }>
    <hgroup className={ `${BASE_CLASS}__label` }>{ field.label }</hgroup>
    <ol className={ `${BASE_CLASS}__choices` }>
      { field.choices.map((choice) => (
        <li className={ `${BASE_CLASS}__choice` } key={ choice.value }>
          <RadioChoice { ...{
            choice,
            fieldName,
            formFieldProps,
          } } />
        </li>
      )) }
    </ol>
  </section>;
}
