import React, { useState } from 'react';
import createLogger from '../helpers/Logger';
import { register } from '../serviceWorkerRegistration';

const debug = createLogger('ServiceWorkerContext');

/**
 * Base definition of the ServiceWorkerContext
 *
 * These properties are React stateful and are tracked and updated
 * as the service-worker gets installed.
 */
export const ServiceWorkerContext = {
  /** @type {ServiceWorkerRegistration | null} */
  serviceWorkerRegistration: null,
  isActive: false,
  isInstalling: false,
  hasUpdated: false,
};

/** @type {React.Context} */
const ServiceWorkerReactContext = React.createContext(ServiceWorkerContext);

/**
 * This hook gives you access to the ServiceWorker lifecycle, so that you
 * can react to it's installation and or update.
 *
 * @returns {ServiceWorkerContext} - Hooks to ServiceWorker life-cycle.
 */
export function useServiceWorker() {
  const context = React.useContext(ServiceWorkerReactContext);
  if (context === undefined) {
    throw new Error(
        '`useAuthentication` must be used with an `AuthenticationProvider`',
    );
  }
  return context;
}

/**
 * @param {ServiceWorkerRegistration} serviceWorkerRegistration
 *        Known (potential registration
 * @param {ServiceWorkerContainer} serviceWorkerContainer
 *        Service worker container
 * @param {Function} setServiceWorkerRegistration
 *        Callback function to set the registration to react state
 * @param {Function} setActive
 *        Callback function to set active state of service-worker
 *
 * @returns {ServiceWorkerRegistration}
 *         The actual registration, if one can be found...
 */
function getServiceWorkerState(
    serviceWorkerRegistration,
    serviceWorkerContainer,
    setServiceWorkerRegistration,
    setActive,
) {
  if (serviceWorkerRegistration) {
    if (
      serviceWorkerRegistration.active &&
      serviceWorkerRegistration.active.state === 'activated'
    ) {
      debug('Active ServiceWorker in use');
      setActive(true);
    }
  }
  setServiceWorkerRegistration(serviceWorkerRegistration);
  return serviceWorkerRegistration;
}

/**
 * @param {{
 *  children: JSX.Element[] | JSX.Element | null,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export function ServiceWorkerProviderComponent({ children }) {
  // Make a call to register the service-worker, the rest of
  // this component will correctly update it's state to reflect
  // the service-worker's state.
  register({});

  const serviceWorkerContainer = navigator.serviceWorker;
  const [
    serviceWorkerRegistration,
    setServiceWorkerRegistration,
  ] = useState(null);
  const [ hasInitialized, setInitialized ] = useState(false);
  const [ isActive, setActive ] = useState(false);
  const [ isInstalling, setInstalling ] = useState(true);
  const [ hasUpdated, setUpdated ] = useState(false);

  React.useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      'serviceWorker' in navigator
    ) {
      navigator.serviceWorker.ready.then(() => {
        debug('ServiceWorker is ready');
        setInitialized(true);
        setInstalling(true);
      });
    } else {
      debug('ServiceWorker won\'t play');
      setInitialized(true);
      setInstalling(false);
    }
  }, []);

  React.useEffect(() => {
    if (hasInitialized && serviceWorkerContainer) {
      serviceWorkerContainer.getRegistration()
          .then((serviceWorkerRegistration) => getServiceWorkerState(
              serviceWorkerRegistration,
              serviceWorkerContainer,
              setServiceWorkerRegistration,
              setActive,
          ))
          .then((serviceWorkerRegistration) => {
            if (serviceWorkerRegistration) {
              serviceWorkerRegistration.addEventListener(
                  'updatefound',
                  () => {
                    debug('Update found');
                    setUpdated(true);
                  },
              );
            }
          })
          .then(() => setInstalling(false))
      ;
    }
  }, [ serviceWorkerContainer, hasInitialized ]);

  const context = {
    serviceWorkerRegistration,
    isActive,
    isInstalling,
    hasUpdated,
  };
  return <ServiceWorkerReactContext.Provider value={ context }>
    { children }
  </ServiceWorkerReactContext.Provider>;
}
