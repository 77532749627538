import React from 'react';

const BASE_CLASS = 'in-progress';

/**
 * @param {{
 *   className?: string,
 *   inProgress: boolean,
 * }} arg React props
 * @returns {JSX.Element | null} ...
 */
export default function InProgress({
  className,
  inProgress,
}) {
  const baseClass = className || BASE_CLASS;
  return inProgress ? (<div className={ baseClass }>
    <svg className={ `${baseClass}__ring` } width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
          <path d="M36 18c0-9.94-8.06-18-18-18">
          </path>
        </g>
      </g>
    </svg>
  </div>) : null;
}
