import React from 'react';
import useTextField from './useFormField';

const BASE_CLASS = 'hidden-field';

/**
 * @param {{
 *  field: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function HiddenField({ field }) {
  const fieldProps = useTextField(field.id);
  return <input
    type='hidden'
    placeholder={ field.label }
    className={ `${BASE_CLASS}__input--${field.size}` }
    required={ field.isRequired }
    { ...fieldProps }
  />;
}
