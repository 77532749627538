// Circumvent sentry capture
const capture = [
  // Using console.error will provide expandable stacktrace
  // allowing us to find actual code-point where debug is
  // invoked
  'error',
];

/** @type {Object<string, Function>} */
const orgConsole = capture.reduce(
    /**
     * @param {Object<string, Function>} acc ...
     * @param {string} method ...
     * @returns {Object<string, Function>} ...
     */
    function cloneConsole(acc, method) {
      return {
        ...acc,
        // eslint-disable-next-line
      [method]: console[method],
      };
    }, {});

/**
 * @param {string} scope A 'scope' to pass along with messages
 * @param {...any} args Args, will be passed along to actual console log
 */
function debug(scope, ...args) {
  orgConsole.error.apply(
      null,
      [
        `%c${scope}`,
        `
        padding: 0.1em 0.5em;
        color: white;
        background: #A00;
        border-radius: 0.3em;
      `,
        ...args,
      ],
  );
}

/**
 * @param {string} scope A 'scope' to pass along with messages
 * @returns {Function} A function to log and debug messages, skipping
 *          potential 3rd party loggers (such as Sentry)
 */
export default function createLogger(scope) {
  return debug.bind(null, scope);
}
