import React from 'react';
import { useHistory, useParams } from 'react-router';
import { NAVIGATION_TYPES } from '../Constants';
import InProgress from '../helpers/InProgress';
import { refactorUrl } from '../helpers/url';
import { useNavigation } from '../navigation/useNavigation';
import PageView from './PageView';

/**
 * @param {{
 *  offline?: boolean
 * }} props React props
 * @returns {JSX.Element|null} ...
 */
export default function SlugRouter(props) {
  const history = useHistory();

  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  const topic = NAVIGATION_TYPES[slug];

  const navitems = useNavigation(topic);

  // If this slug does not exist as topic, just go ahead
  // and show the page
  if (!topic) {
    return <PageView { ...props } />;
  }

  // Wait for navitems to load...
  if (navitems.length > 0) {
    history.push(refactorUrl(navitems[0].url));
  }

  // Meanwhile, show a spinner (never seen it, navitems are in too fast)
  return <InProgress inProgress={ navitems.length === 0 } />;
}
