import React from 'react';
import useTextField from './useFormField';

const BASE_CLASS = 'text-area';

/**
 * @param {{
 *  field: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function TextArea({ field }) {
  const fieldProps = useTextField(field.id);

  return <label className={ `${BASE_CLASS}` }>
    <hgroup className={ `${BASE_CLASS}__label` }>{ field.label }</hgroup>
    <textarea
      placeholder={ field.label }
      className={ `${BASE_CLASS}__input--${field.size}` }
      { ...fieldProps }
    />
  </label>;
}
