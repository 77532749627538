import React, { useEffect, useState } from 'react';
import { oktaAuth } from '../App';
import { postForm } from '../dao/forms';
import FormFieldFactory from './FormFieldFactory';

const FIELD_TRANSFORMERS = {
  'First name': async function(field) {
    const user = await oktaAuth.getUser();
    return user.given_name;
  },
  'Last name': async function(field) {
    const user = await oktaAuth.getUser();
    return user.family_name;
  },
  'Email': async function(field) {
    const user = await oktaAuth.getUser();
    return user.email;
  },
};

/**
 * @typedef FieldValueObject
 * @property {string} label Desciption of the form-value
 * @property {string | number} value The actual form-value
 */

/**
 * @typedef FormContext
 * @property {Object<string, FieldValueObject>} formContext Context values
 * @property {Function} setFormContext Setter for the context values
 */

const FormContext = React.createContext({
  formContext: {},
  setFormContext:
    /** @type {import('react').Dispatch<import('react').SetStateAction<{}>>} */
    function() {},
});

/**
 * @param {string} unsafeString A potentially unsafe string for HTML id's
 * @returns {string} All non-alpha characters replaced by `_`
 */
function makeIDSafe(unsafeString) {
  return unsafeString ? unsafeString.replace(/[^a-z]+/gi, '_') : 'forty2';
}

/**
 * @returns {FormContext} The FormContext
 */
export function useFormContext() {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error(
        '`useFormContext` must be used within a `FormContextProvider`',
    );
  }
  return context;
}

/**
 * @param {Object<string, any>} field The field definitiona
 * @returns {Promise<string>} the default value of the field
 */
async function determineDefaultValue(field) {
  if (field.choices) {
    return field.choices.find((choice) => choice.isSelected).value;
  }
  if (field?.visibility === 'hidden') {
    const fieldTransform = FIELD_TRANSFORMERS[field.label];
    if (fieldTransform) {
      return fieldTransform(field);
    }
  }
  return field.defaultValue;
}

/**
 * @param {Object<string, any>} formData Form data from the API
 * @returns {Promise<Object<string, any>>} A simplified view of the form-fields
 */
async function reduceFormDataToContext(formData) {
  // Order is not guaranteed
  const values = await Promise.all(formData.fields.map(
      async (field) => {
        const value = await determineDefaultValue(field);
        return ({
          id: field.id,
          label: field.label,
          value,
        });
      },
  ));
  return values.reduce(
      (acc, field) => ({
        ...acc,
        [field.id]: {
          label: field.label,
          value: field.value,
        },
      }),
      {},
  );
}

/**
 * @param {{
 *  formData: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FormFactory({ formData }) {
  const [ formContext, setFormContext ] = useState({});
  const [ inProgress, setInProgress ] = useState(false);
  const [ result, setResult ] = useState(null);

  /**
   *
   */
  function blockAndPostForm() {
    setInProgress(true);
    postForm(formData.id, formContext)
        .then((postResult) => {
          setResult(postResult);
        })
        .finally(() => setInProgress(false))
    ;
  }

  useEffect(() => {
    if (formData?.fields) {
      reduceFormDataToContext(formData).then(
          setFormContext,
      );
    }
  }, [ formData ]);

  if (!formData?.fields || Object.keys(formContext).length < 1) {
    return <div className='form-factory--placeholder'>
      <div className='form-factory__input--placeholder' />
      <ol className='form-factory__group--placeholder'>
        <li className='form-factory__choice--placeholder'>
          <div className='form-factory__input--placeholder' />
        </li>
        <li className='form-factory__choice--placeholder'>
          <div className='form-factory__input--placeholder' />
        </li>
        <li className='form-factory__choice--placeholder'>
          <div className='form-factory__input--placeholder' />
        </li>
      </ol>
      <div className='form-factory__text-area--placeholder' />
      <div className='form-factory__text-area--placeholder' />
      <div className='form-factory__text-area--placeholder' />
    </div>;
  }

  if (result && result.confirmation_message) {
    return (<div dangerouslySetInnerHTML={ {
      __html: result.confirmation_message,
    } }
    className="form-factory__confirmation" />);
  }

  return <FormContext.Provider value={ {
    formContext,
    setFormContext,
  } }>
    <form
      className='form-factory'
      id={ `form${makeIDSafe(formData.title)}` }
      onSubmit={ (e) => {
        e.preventDefault();
        blockAndPostForm();
      } }
    >
      { inProgress && (<div className='form-factory__in-progress'>
        <svg className="progress-ring" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
              </path>
            </g>
          </g>
        </svg>
      </div>) }
      { formData.fields.map((field) => (
        <FormFieldFactory field={ field } key={ field.id } />
      )) }
      <button
        className='form-factory__cta'
        disabled={ inProgress }
        type="submit"
      >{ formData.button.text }</button>
    </form>
  </FormContext.Provider>;
}
