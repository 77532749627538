import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigation } from './useNavigation';
import Icon from '../icons/Icon';
import { refactorUrl } from './../helpers/url';
import logo from './../assets/images/Callidus-logo.png';

/**
 * Show the logo
 *
 * @returns {JSX.Element} The logo.
 */
function Logo() {
  return (
    <NavLink to="/">
      <img className="navigation-view__logo" src={ logo } alt="Callidus"/>
    </NavLink>
  );
}

/**
 * @param {string} navLinkUrl The url the navlink points to
 * @param {import('react-router').match} match Router match
 * @param {{
 *   pathname: string,
 * }} location Current browser location
 *
 * @returns {boolean} Match or not...
 */
function isActive(navLinkUrl, match, location) {
  if (!match) {
    return false;
  }

  if (navLinkUrl === '/') {
    return navLinkUrl === location.pathname;
  }

  return location.pathname.startsWith(navLinkUrl);
}

/**
 * @returns {JSX.Element} React component
 */
export default function NavigationView() {
  /** @type {import('../Callidus').Navitem[]} */
  const navitems = useNavigation('primary');
  return <nav className="navigation-view">
    <div className="navigation-view__container">
      <Logo />
      <ul className="navigation-view__list">
        { navitems.map((navitem) => (
          <NavLink
            className="navigation-view__item"
            key={ navitem.object_id }
            to={ refactorUrl(navitem.url) }
            isActive = { isActive.bind(null, refactorUrl(navitem.url)) }
          >
            { <Icon icon={ navitem.classes }/> }
            { navitem.title }
          </NavLink>
        )) }
      </ul>
    </div>
  </nav>;
}
