/** @typedef {import('../Callidus').Reply} Reply */
/** @typedef {import('../Callidus').SparsePost} SparsePost */
import React, { useCallback, useEffect, useState } from 'react';
import TimeStamp from '../time/TimeStamp';
import ReplyForm from './ReplyForm';
import { useRepliesByPost } from './useRepliesByPost';

/**
 * @param {{
 *   answer: Reply,
 * }} arg React props
 * @returns {JSX.Element} A super sparse Reply
 */
function Answer({
  answer,
}) {
  return <div className="answer">
    <span className="answer__name">{ answer.author_name }</span>
    <div className="answer__content" dangerouslySetInnerHTML={ {
      __html: answer.content.rendered,
    } } />

  </div>;
}

/**
 * @param {{
 *   show: boolean,
 *   post: SparsePost,
 *   parent: Reply,
 *   onReply: Function,
 * }} arg React props
 * @returns {null|JSX.Element} The option to reply
 */
function Reply({
  show,
  post,
  parent,
  onReply,
}) {
  if (!show) {
    return null;
  }

  return <ReplyForm { ...{
    post,
    parent,
    onReply,
    autoFocus: true,
  } }/>;
}

/**
 * Show a listing of a single post
 *
 * @param {{
 *   comment: Reply,
 *   post: SparsePost,
 *   replies: Reply[],
 *   onReply: Function,
 * }} arg React props
 * @returns {JSX.Element} An `article` representing a single post.
 */
function Comment({
  comment,
  post,
  replies,
  onReply,
}) {
  const [ reply, setReply ] = useState(false);
  const answers = replies.filter((r) => r.parent === comment.id);

  /**
   * @param {{
   *   preventDefault: Function,
   * }} e Just a mouse-event
   */
  function onClickHandler(e) {
    e.preventDefault();
    setReply(!reply);
  }

  /**
   * @param {...any} args Just passing through
   * @returns {any} Just passing through
   */
  function closeOnReply(...args) {
    setReply(false);
    return onReply(...args);
  }

  return (
    <section className="replies-list__container">
      <article
        className="replies-list__comment"
      >
        <h3 className="replies-list__name">{ comment.author_name }</h3>
        <div className="replies-list__excerpt" dangerouslySetInnerHTML={ {
          __html: comment.content.rendered,
        } } />
      </article>
      <TimeStamp date={ comment.date } className="replies-list__timestamp" />
      <a
        className="replies-list__action"
        href="#reply"
        onClick={ onClickHandler }
      >Reply</a>
      <Reply
        show={ reply }
        post={ post }
        parent={ comment }
        onReply={ closeOnReply }
      />
      <nav className="replies-list--sub">{ answers.map((answer) => (
        <Answer key={ answer.id } answer={ answer } />
      )) }</nav>
    </section>
  );
}

/**
 * @param {{
 *   post: SparsePost,
 *   setOnReply: Function,
 * }} arg React props
 * @returns {JSX.Element} The goods
 */
export default function RepliesList({ post, setOnReply }) {
  const [ replies, refresh ] = useRepliesByPost(post);
  const onReply = useCallback(function onReply() {
    refresh();
  }, [ refresh ]);
  useEffect(() => {
    setOnReply(() => onReply);
  }, [ onReply, setOnReply ]);

  const rootReplies = replies.filter((r) => r.parent === 0);
  return <nav className="replies-list">{ rootReplies.map((reply) => (
    <Comment key={ reply.id } comment={ reply } { ...{
      post,
      replies,
      onReply,
    } }/>
  )) }</nav>;
}
