import { enc, HmacSHA1 as hmacSHA1 } from 'crypto-js';
import OAuth from 'oauth-1.0a';
import { FORMS_API, FORMS_API_KEY, FORMS_API_SECRET } from '../Constants';

/**
 * @param {string} baseString Message to hash
 * @param {string} key Key to use as hashing config
 * @returns {string} Hashed string of original content using key
 */
function hash(baseString, key) {
  return hmacSHA1(baseString, key).toString(enc.Base64);
}

// As we currently need to run this oAuth1 client-side, secrets will be baked
// into app regardless. No need to handle them with care...
const oAuth = new OAuth({
  consumer: {
    key: FORMS_API_KEY,
    secret: FORMS_API_SECRET,
  },
  signature_method: 'HMAC-SHA1',
  hash_function: hash,
});

/**
 * @param {string} formId Identifier for the form we're fetching
 * @returns {Promise<Object<string, any>>} Resolves to the JSON of the response
 */
export async function fetchForm(formId) {
  const requestData = {
    url: `${FORMS_API}/${formId}`,
    method: 'GET',
    data: '',
  };
  const oAuthHeaders = oAuth.toHeader(oAuth.authorize(requestData));
  const response = await fetch(requestData.url, {
    method: requestData.method,
    headers: {
      ...oAuthHeaders,
      'Content-Type': 'application/json',
    },
  });

  return response.json();
}

/**
 * @param {Object<string, any>} formContext The form-field name/value pairs
 * @returns {FormData} flattened context
 */
function contextToFormData(formContext) {
  return Object.keys(formContext).reduce(
      (formData, fieldID) => {
        formData.append(`input_${fieldID}`, formContext[fieldID].value);
        return formData;
      },
      new FormData(),
  );
}

/**
 * @param {string} formId Identifier for the form we're fetching
 * @param {Object<string, any>} formContext The form-field name/value pairs
 * @returns {Promise<Object<string, any>>} Resolves to the JSON of the response
 */
export async function postForm(formId, formContext) {
  const response = await fetch(
      `${FORMS_API}/${formId}/submissions`,
      {
        method: 'POST',
        body: contextToFormData(formContext),
      },
  );

  return response.json();
}
