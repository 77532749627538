import { useFormContext } from './FormFactory';

/**
 * @typedef {Object} GenericFormElement
 * @property {string} value The value of the form-element
 */

/**
 * @param {string} id Form field id
 * @returns {{
 *   value: string | number,
 *   onChange: import('react').ChangeEventHandler<GenericFormElement>,
 * }} Basic properties for a text-field to work in React
 */
export default function useTextField(id) {
  const { formContext, setFormContext } = useFormContext();
  const field = formContext[id] || {
    value: '',
  };

  /**
   * @param {{
   *  target: {
   *    value: string,
   *  }
   * }} event Change event
   */
  function onChange(event) {
    setFormContext({
      ...formContext,
      [id]: {
        ...field,
        value: event.target.value,
      },
    });
  }

  return {
    value: field.value,
    onChange,
  };
}
