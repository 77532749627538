import {
  addCountToPost,
  fetchCountsByPost,
  retractCountFromPost,
} from './counters';

const API_ENDPOINT = '/likes';
export const LIKES_CUSTOM_FIELDNAME = '_cd_like_count';

/**
 * Fetch the replies for a specific post.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch likes
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function fetchLikesByPost(post) {
  return fetchCountsByPost(API_ENDPOINT, post);
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to add a like
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function addLikeToPost(post) {
  return addCountToPost(API_ENDPOINT, post);
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to add a like
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function retractLikeFromPost(post) {
  return retractCountFromPost(API_ENDPOINT, post);
}

