import { useEffect, useState } from 'react';
import { fetchRepliesCountByPost } from '../dao/comments';

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch replies count
 * @returns {[boolean, number, Function, boolean]} [postCount, isInitialized]
 */
export default function useRepliesCount(post) {
  const [ count, setCount ] = useState(0);
  const [ isInitialized, setInitialized ] = useState(false);

  useEffect(() => {
    fetchRepliesCountByPost(post).then(
        async function handleCountRepliesResult(result) {
          setCount(result);
          setInitialized(true);
        },
    );
  });

  // Padding out returned vallue to match other 'useCounter' hooks
  return [ false, count, () => {}, isInitialized ];
}
