import { useEffect, useState } from 'react';
import { fetchNavigationByLocation } from '../dao/navigation';

/** @type {import('../Callidus').Navitem[]} */
const navigationCache = [];

/**
 * Basic hook to fetch navigation from the server and cache it in state.
 *
 * @param {string} location Location for the post
 *
 * @returns {Array<import('../Callidus').Navitem>}
 *          returns the `navigation` state.
 */
export function useNavigation(location) {
  const [ navigation, setNavigation ] = useState(
      navigationCache[location] || [],
  );

  useEffect(() => {
    if (location) {
      fetchNavigationByLocation(location)
          .then((navigation) => navigationCache[location] = navigation)
          .then((navigation) => setNavigation(navigation))
      ;
    }
  }, [ location ]);

  return navigation;
}
