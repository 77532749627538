export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

export const FORMS_API_KEY = process.env.REACT_APP_FORMS_API_KEY;
export const FORMS_API_SECRET = process.env.REACT_APP_FORMS_API_SECRET;

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const API_ROOT = `${BACKEND_URL}/wp-json`;
export const CORE_API = `${API_ROOT}/wp/v2`;
export const NAV_API = `${API_ROOT}/wp-api-menus/v2`;
export const META_API = `${API_ROOT}/meta/v1`;
export const FORMS_API = `${API_ROOT}/gf/v2/forms`;

export const NAVIGATION_TYPES = {
  'hr': 'contact',
  'callidus': 'news',
  'offline': 'offline',
  'charts': 'charts',
};
export const EVENTS_TYPE = 'events';
