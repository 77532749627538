import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CategoriesList from '../categories/CategoriesList';
import RepliesList from '../comments/RepliesList';
import ReplyForm from '../comments/ReplyForm';
import TimeStamp from '../time/TimeStamp';
import { usePost } from './usePosts';
import Icon from '../icons/Icon';
import useLike from './useLike';
import useView from './useView';
import { urlParser } from '../helpers/url';

const like = 'icon-thumbs-up';

/**
 * @param {{
 *   preventDefault: Function,
 * }} e mouse-event from DOM...
 * @returns {boolean} false
 */
function goBack(e) {
  e.preventDefault();
  window.history.go(-1);
  return false;
}

/**
 * @param {{
 *   post: import('../Callidus').Post | import('../Callidus').SparsePost,
 *   likeButton: JSX.Element,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export function BasePostView({ post, likeButton }) {
  /** @type {[Function, Function]} */
  const [ onReply, setOnReply ] = useState(undefined);
  const [ isViewed,, countView, isViewsInitialized ] = useView(post);

  useEffect(() => {
    if (isViewsInitialized && !isViewed) {
      countView(true);
    }
  }, [ post, isViewsInitialized, countView, isViewed ]);
  if (!post) {
    return <div className="progress-spinner">In progress</div>;
  }

  const postStyle = {
    backgroundImage: `url('${
      post._embedded?.['wp:featuredmedia']?.[0].source_url
    }')`,
  };

  return <article className='post-view'>
    <a href="#back" onClick={ goBack } className="post-view__back">Back</a>
    <div className="post-view__media" style={ postStyle } />
    <CategoriesList post={ post } />
    <h2 className="post-view__title" dangerouslySetInnerHTML={ {
      __html: post.title?.rendered,
    } } />
    <TimeStamp date={ post.date } className="post-view__timestamp" />
    <div className="post-view__content" dangerouslySetInnerHTML={ {
      // REASON: optional chaining resolves Sparse vs full type
      // @ts-ignore
      __html: urlParser(post.content?.rendered),
    } } />

    { likeButton }

    <ReplyForm { ...{
      post,
      onReply,
    } }/>

    <RepliesList { ...{
      post,
      setOnReply,
    } } />
  </article>;
}

/**
 * @param {{
 *   hook?: Function,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export default function PostView({ hook }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  /** @type {import('../Callidus').Post | import('../Callidus').SparsePost} */
  const post = usePost(slug);

  const [ isLiked, likesCount, setLiked ] = useLike(post);

  /**
   * @param {import('react').SyntheticEvent} e Actual mouse-event
   */
  function clickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    setLiked(!isLiked);
  }

  return <BasePostView
    post={ post }
    likeButton={ (<a
      href="#like"
      rel="like"
      className={ `post-view__star${isLiked ? '--liked' : ''}` }
      onClick={ clickHandler }
    ><Icon icon={ like }/>{ likesCount }</a>) }
  />;
}
