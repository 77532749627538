import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

export const AXIS = {
  HORIZONTAL: 1,
  VERTICAL: 2,
  BOTH: 3,
};

/**
 * @param {number} position Current scroll-position
 * @param {number} scrollSize Total size of content
 * @param {number} displaySize Totoal size availabel for display
 *
 * @returns {boolean[]}
 *  An array of booleans indicating existence of overflow at start and or end
 */
function calculateEdges(position, scrollSize, displaySize) {
  // > 1 is used to negate rounding errors
  const hasScollContentStart = position > 0;
  const hasScollContentEnd = scrollSize - displaySize - position > 1;
  return [
    hasScollContentStart,
    hasScollContentEnd,
  ];
}

/**
 * @param {HTMLElement} target The dom-element under evaluation
 * @param {number} [axis] Which axis to check
 * @returns {{
 *   top: boolean,
 *   bottom: boolean,
 *   left: boolean,
 *   right: boolean,
 * }} A set of booleans indicating overflow exists (or not) in that direction
 */
function getScrollState(target, axis = AXIS.BOTH) {
  const scrollState = {};
  if (axis & AXIS.VERTICAL) {
    const {
      scrollTop,
      scrollHeight,
      offsetHeight,
    } = target;
    const [ hasScrollTop, hasScrollBottom ] = calculateEdges(
        scrollTop,
        scrollHeight,
        offsetHeight,
    );

    scrollState.top = hasScrollTop;
    scrollState.bottom = hasScrollBottom;
  }

  if (axis & AXIS.HORIZONTAL) {
    const {
      scrollLeft,
      scrollWidth,
      offsetWidth,
    } = target;
    const [ hasScrollLeft, hasScrollRight ] = calculateEdges(
        scrollLeft,
        scrollWidth,
        offsetWidth,
    );

    scrollState.left = hasScrollLeft;
    scrollState.right = hasScrollRight;
  }

  return scrollState;
}

/**
 * @param {HTMLElement} target The element on which to switch classes
 * @param {number} [axis] Which axis to check
 * @returns {string[]} The classes that should be applied
 */
function getScrollClasses(target, axis) {
  const classes = [];
  const scrollState = getScrollState(target, axis);
  Object.keys(scrollState).forEach(function(side) {
    const cn = `scroll-indicator--${side}`;
    if (scrollState[side]) {
      classes.push(cn);
    }
  });

  return classes;
}

/**
 * This hook rigs up the event-handlers and classnames to generate
 * a scrollable element with indicators in which direction overflow
 * exists.
 *
 * Example:
 * ```javascript
 * const scrollableProps = useScrollable();
 *
 * return <div {...scrollableProps}>
 *   Some lengthy content
 * </div>
 * ```
 *
 * When other handlers need to be attached to the same events as those
 * in use by this hook, untangle them first and add them individually.
 *
 * @param {number} [axis] Which axis to show scroll-state for.
 * @param {boolean} [waitingForContent] Optional param, true if still waiting
 * @returns {{
 *   ref: import('react').MutableRefObject,
 *   onScroll: import('react').UIEventHandler,
 *   className: string,
 * }} Props that need to be set on the 'scrollable' element
 */
export default function useScrollable(axis, waitingForContent = false) {
  const [ domNode, setDomNode ] = useState(null);
  const [ classes, setClasses ] = useState([]);

  const initDomNode = useCallback(function initDomNode(node) {
    console.warn('new node created...');
    if (node !== null) {
      const classes = getScrollClasses(node, axis);
      setClasses(classes);
    }
    setDomNode(node);
  }, [ axis ]);

  /**
   * Generic handler for both scroll and resize events
   */
  const handler = useCallback(function handler() {
    if (domNode && !waitingForContent) {
      setClasses(getScrollClasses(domNode, axis));
    }
  }, [ waitingForContent, domNode, axis ]);

  useEffect(() => {
    window.addEventListener('resize', handler);
    handler();

    return function cleanUp() {
      window.removeEventListener('resize', handler);
    };
  }, [ handler ]);

  return {
    ref: initDomNode,
    onScroll: handler,
    className: classes.join(' '),
  };
}
