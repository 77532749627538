/** @typedef {import('../Callidus').Reply} Reply */
import { useCallback, useEffect, useState } from 'react';
import { fetchRepliesByPost } from '../dao/comments';

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch replies
 * @returns {[Reply[], Function]} List of replies
 */
export function useRepliesByPost(post) {
  const [ replies, setReplies ] = useState([]);
  const refresh = useCallback(function refresh() {
    fetchRepliesByPost(post).then(setReplies);
  }, [ post ]);

  useEffect(function onMount() {
    refresh();
  }, [ refresh ]);

  return [ replies, refresh ];
}
