import { useEffect, useState } from 'react';
import { fetchPageBySlug } from '../dao/pages';

/**
 * @param {string} slug Unique slug for the page
 * @returns {import('../Callidus').Page}
 *          Returns a `Page` as a state-controlled value
 */
export default function usePage(slug) {
  const [ page, setPage ] = useState(null);

  useEffect(() => {
    fetchPageBySlug(slug)
        .then((page) => setPage(page || null))
    ;
  }, [ slug ]);

  return page;
}
