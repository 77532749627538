/**
 * Icons in use are sourced from
 * https://www.carbondesignsystem.com/guidelines/icons/library/
 */
import React from 'react';
import { ReactComponent as Building } from './../assets/icons/building.svg';
import { ReactComponent as Bullhorn } from './../assets/icons/bullhorn.svg';
import {
  ReactComponent as ChatHistogram,
} from './../assets/icons/chart--histogram.svg';
import { ReactComponent as ChatBot } from './../assets/icons/chat-bot.svg';
import { ReactComponent as Chat } from './../assets/icons/chat.svg';
import { ReactComponent as Checkmark } from './../assets/icons/checkmark.svg';
import {
  ReactComponent as ChevronLeft,
} from './../assets/icons/chevron--left.svg';
import {
  ReactComponent as ChevronRight,
} from './../assets/icons/chevron--right.svg';
import { ReactComponent as ChevronUp } from './../assets/icons/chevron--up.svg';
import {
  ReactComponent as DocumentPdf,
} from './../assets/icons/document--pdf.svg';
import {
  ReactComponent as EventSchedule,
} from './../assets/icons/event--schedule.svg';
import { ReactComponent as Events } from './../assets/icons/events.svg';
import { ReactComponent as HR } from './../assets/icons/hr.svg';
import { ReactComponent as Printer } from './../assets/icons/printer.svg';
import { ReactComponent as SendAlt } from './../assets/icons/send--alt.svg';
import { ReactComponent as Star } from './../assets/icons/star.svg';
import { ReactComponent as ThumbsUp } from './../assets/icons/thumbs-up.svg';
import { ReactComponent as Time } from './../assets/icons/time.svg';
import { ReactComponent as View } from './../assets/icons/view.svg';

const iconDict = {
  'icon-building': <Building />,
  'icon-bullhorn': <Bullhorn />,
  'icon-chart-histogram': <ChatHistogram/>,
  'icon-chat-bot': <ChatBot/>,
  'icon-chat': <Chat/>,
  'icon-checkmark': <Checkmark />,
  'icon-chevron-left': <ChevronLeft/>,
  'icon-chevron-right': <ChevronRight/>,
  'icon-chevron-up': <ChevronUp/>,
  'icon-document-pdf': <DocumentPdf/>,
  'icon-event-schedule': <EventSchedule/>,
  'icon-events': <Events/>,
  'icon-hr': <HR/>,
  'icon-printer': <Printer/>,
  'icon-send-alt': <SendAlt/>,
  'icon-star': <Star/>,
  'icon-thumbs-up': <ThumbsUp/>,
  'icon-time': <Time/>,
  'icon-view': <View/>,
};

/**
 * Show an icon
 *
 * @param {{
 *   icon: string,
 * }} arg React Props
 * @returns {JSX.Element | null} The icon component.
 */
export default function Icon({ icon }) {
  if (!iconDict[icon]) return null;
  return iconDict[icon];
}
