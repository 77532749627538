import { fetchJSONFromAPI } from './api';

const EVENT_TYPE = 'callidus-events';
const API_ENDPOINT = `/${EVENT_TYPE}`;

/**
 * Fetch all events
 *
 * @returns {Promise<import('../Callidus').SparseEvent[]>} resolves to posts.
 */
export async function fetchEvents() {
  return fetchJSONFromAPI(
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        '_fields': [
          'categories',
          'date',
          'excerpt',
          'id',
          'slug',
          'title',
          'type',
          'event_custom_fields',
          '_links', // This is required to get _embedded props
        ].join(','),
      },
  );
}

/**
 * Fetch a single Event by slug
 *
 * @param {string} slug The unique slug of the Event
 * @returns {Promise<import('../Callidus').Event>} resolves to Events.
 */
export async function fetchEventBySlug(slug) {
  const Events = await fetchJSONFromAPI(
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        'slug': slug,
      },
  );

  return Events.shift(); // Take the first Event of the top
}
