import { fetchJSONFromAPI } from './api';

const API_ENDPOINT = '/categories';

/**
 * Fetch all categories
 *
 * @returns {Promise<import('../Callidus').Category[]>} resolves to posts.
 */
export async function fetchCategories() {
  return fetchJSONFromAPI(API_ENDPOINT, {
    'per_page': '50',
    '_fields': [
      'id', 'count', 'name', 'slug',
    ].join(','),
  });
}
