/** @typedef {import('../Callidus').SparsePost} SparsePost */
/** @typedef {import('../Callidus').Post} Post */
import { useEffect, useState } from 'react';
import { fetchPostBySlug, fetchPosts } from '../dao/posts';

/** @type {SparsePost[]} */
let postsCache = [];

/**
 * Basic hook to fetch posts from the server and cache it in state.
 *
 * @returns {Array<SparsePost[]>} returns the `posts` state.
 */
export default function usePosts() {
  const [ posts, setPosts ] = useState(postsCache);

  useEffect(() => {
    fetchPosts()
        .then((posts) => postsCache = posts)
        .then((posts) => setPosts(posts))
    ;
  }, []);

  return [ posts ];
}

/**
 * @param {string} slug Unique slug for the post
 * @returns {Post | SparsePost}
 *          Returns a `Post` as a state-controlled value
 */
export function usePost(slug) {
  const [ post, setPost ] = useState(
      postsCache.find((post) => post.slug === slug),
  );

  useEffect(() => {
    fetchPostBySlug(slug)
        .then((post) => setPost(post || null))
    ;
  }, [ slug ]);

  return post;
}
