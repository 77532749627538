import React from 'react';
import SparseEventBlock from '../events/SparseEventBlock';
import SparsePostBlock from './SparsePostBlock';

const TEMPLATES = {
  'post': SparsePostBlock,
  'event': SparseEventBlock,
};

/**
 * @param {Object<string, any>} props React props pass-through
 * @returns {JSX.Element} React component
 */
export default function BlockViewFactory(props) {
  const Template = TEMPLATES[props.post.type] || SparseEventBlock;

  return <Template { ...props } />;
}
