import {
  addCountToPost,
  fetchCountsByPost,
  retractCountFromPost,
} from './counters';

const API_ENDPOINT = '/views';
export const VIEWS_CUSTOM_FIELDNAME = '_cd_view_count';

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch views
 * @returns {Promise<string>}
 *          Resolves to the views
 */
export async function fetchViewsByPost(post) {
  return fetchCountsByPost(API_ENDPOINT, post);
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch views
 * @returns {Promise<string>}
 *          Resolves to the views
 */
export async function addViewToPost(post) {
  return addCountToPost(API_ENDPOINT, post);
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to retract a view
 * @returns {Promise<any>}
 *          Resolves to the view
 */
export async function retractViewFromPost(post) {
  return retractCountFromPost(API_ENDPOINT, post);
}
